import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>Page Not Found</h1>
    <p>
      <i>Just lightin&apos; out for the territories, huh? Brother, I been there.</i>
    </p>
  </Layout>
)

export default NotFoundPage
